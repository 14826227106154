//@ts-nocheck
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import * as s3Service from '../../lib/s3Service';
import { toast } from 'react-hot-toast';
import Loader from '../../components/Loader';
import FolderList from '../../components/S3/FolderList';
import { S3Object, S3Folder } from '../../types/s3';
import ObjectList from '../../components/S3/ObjectList';
import ConfirmationModal from '../../components/ConfirmationModal';
import CustomFileUpload from '../../components/S3/CustomFileUpload';

interface S3ObjectsResponse {
    objects: S3Object[];
    folders: string[];
    nextContinuationToken: string | null;
}

const S3Explorer: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const prefix = searchParams.get('prefix') ?? '';
    const [isGridView, setIsGridView] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [newFolderName, setNewFolderName] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isCompressModalOpen, setIsCompressModalOpen] = useState<boolean>(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [objectToRename, setObjectToRename] = useState<string | null>(null);
    const [newFileName, setNewFileName] = useState('');

    // Fetch both objects and folders from listObjectsAndFolders
    const fetchObjectsAndFolders = async ({ pageParam = null }) => {
        return s3Service.listObjectsAndFolders(prefix, pageParam);
    };

    const {
        data: objectsAndFoldersData,
        error: objectsAndFoldersError,
        fetchNextPage: fetchNextObjectsPage,
        hasNextPage: hasNextObjectsPage,
        isFetching: isObjectsFetching,
        isFetchingNextPage: isObjectsFetchingNextPage,
        status: objectsStatus,
        // } = useInfiniteQuery<S3ObjectsResponse, Error>({
    } = useInfiniteQuery<S3ObjectsResponse, Error>({
        queryKey: ['objectsAndFolders', prefix],
        queryFn: fetchObjectsAndFolders,
        getNextPageParam: (lastPage) => lastPage.nextContinuationToken,
        // enabled: !!prefix,
    });

    const createFolderMutation = useMutation({
        mutationFn: s3Service.createFolder,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['objectsAndFolders', prefix] });
        },
    });

    const deleteFileMutation = useMutation({
        mutationFn: s3Service.deleteMultipleFiles,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['objectsAndFolders', prefix] });
        },
    });

    const deleteFolderMutation = useMutation({
        mutationFn: s3Service.deleteFolder,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['objectsAndFolders', prefix] });
        },
    });

    const compressImageMutation = useMutation({
        mutationFn: s3Service.compressImage,
        onSuccess: () => {
            // Check if the prefix ends with '/'
            if (prefix.endsWith('/')) {
                // If the prefix ends with '/', we remove the last part (folder) after the last '/'
                const newPrefix = prefix.slice(0, prefix.lastIndexOf('/', prefix.length - 2)) + '/';

                // Debugging: Log both the original and the new prefix
                console.log('Original Prefix:', prefix);
                console.log('New Prefix:', newPrefix);

                // Invalidate queries for the new prefix
                queryClient.invalidateQueries({ queryKey: ['objectsAndFolders', newPrefix] });
            } else if (prefix.includes('/')) {
                // If the prefix contains '/', we remove the last part (folder) after the last '/'
                const newPrefix = prefix.slice(0, prefix.lastIndexOf('/'));

                // Debugging: Log both the original and the new prefix
                console.log('Original Prefix:', prefix);
                console.log('New Prefix:', newPrefix);

                // Invalidate queries for the new prefix
                queryClient.invalidateQueries({ queryKey: ['objectsAndFolders', newPrefix] });
            } else {
                // If it's a root-level prefix, no slash, invalidate root-level query
                console.log('Prefix is at the root level:', prefix);
                queryClient.invalidateQueries({ queryKey: ['objectsAndFolders', prefix] });
            }
        },
    });

    const renameObjectMutation = useMutation({
        mutationFn: ({ oldKey, newKey }: { oldKey: string; newKey: string }) => s3Service.renameObjectInS3(oldKey, newKey),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['objectsAndFolders', prefix] });
        },
    });

    const multipartUploadMutation = useMutation({
        mutationFn: ({ file, prefix }: { file: File; prefix: string }) => s3Service.multipartUploadToS3(file, prefix),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['objectsAndFolders', prefix] });
        },
    });

    const handleFolderClick = (folderPrefix: string) => {
        setSearchParams({ prefix: folderPrefix });
    };

    const handleCreateFolder = async () => {
        if (newFolderName.trim()) {
            const folderKey = `${prefix}${newFolderName}/`;
            try {
                await createFolderMutation.mutateAsync(folderKey);
                setNewFolderName('');
                toast.success('Folder created successfully!');
            } catch (error) {
                toast.error('Failed to create folder');
            }
        }
    };

    const handleDeleteMultiple = async () => {
        try {
            await deleteFileMutation.mutateAsync(selectedItems);
            setSelectedItems([]);
            setIsDeleteModalOpen(false);
            toast.success('Selected items deleted successfully!');
        } catch (error) {
            toast.error('Failed to delete some items');
        }
    };

    const handleCompressImages = async () => {
        try {
            await compressImageMutation.mutateAsync(selectedItems);
            setSelectedItems([]);
            setIsCompressModalOpen(false);
            toast.success('Selected items compressed successfully!');
        } catch (error) {
            toast.error('Failed to compress some items');
        }
    };

    const handleRenameSubmit = async () => {
        if (objectToRename && newFileName.trim()) {
            // Extract the extension from the original file name, if present
            const originalExtension = objectToRename.includes('.') ? `.${objectToRename.split('.').pop()}` : '.webp'; // Default to '.webp' if no extension exists

            // Add the extension to the new file name if it doesn't already have one
            const finalFileName = newFileName.includes('.') ? newFileName.trim() : `${newFileName.trim()}${originalExtension}`;

            // Construct the new S3 key
            const newKey = `${prefix}${finalFileName}`;

            try {
                // Call the mutation to rename the object
                await renameObjectMutation.mutateAsync({ oldKey: objectToRename, newKey });

                // Clear inputs and close the modal on success
                setNewFileName('');
                setIsRenameModalOpen(false);
                toast.success('File renamed successfully!');
            } catch (error) {
                // Show an error toast if renaming fails
                toast.error('Failed to rename file');
            }
        } else {
            // Show an error toast if the new file name is invalid
            toast.error('Please provide a valid file name.');
        }
    };

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            try {
                for (let i = 0; i < files.length; i++) {
                    await multipartUploadMutation.mutateAsync({ file: files[i], prefix });
                }
                toast.success('Files uploaded successfully!');
            } catch (error) {
                toast.error('Failed to upload some files');
            }
        }
    };

    if (isObjectsFetching) {
        return <Loader />;
    }

    if (objectsStatus === 'error') {
        return <div className="text-red-500">Error loading data: {objectsAndFoldersError?.message}</div>;
    }

    console.log('S3Explorer render', objectsAndFoldersData);
    const objects = objectsAndFoldersData?.pages.flatMap((page) => page.objects) || [];
    const folders = objectsAndFoldersData?.pages.flatMap((page) => page.folders) || [];

    return (
        <div className="p-4">
            <div className="mb-4 flex justify-between items-center">
                <h1 className="text-2xl font-bold">S3 Explorer</h1>
                <div className="flex items-center justify-center gap-3">
                    {selectedItems.length > 0 && (
                        <div className=" flex justify-center items-center gap-3">
                            <button className="btn btn-danger" onClick={() => setIsDeleteModalOpen(true)} disabled={deleteFileMutation.isPending || deleteFolderMutation.isPending}>
                                {deleteFileMutation.isPending || deleteFolderMutation.isPending ? 'Deleting...' : `Delete Selected (${selectedItems.length})`}
                            </button>

                            <button className="btn btn-primary" onClick={() => setIsCompressModalOpen(true)} disabled={compressImageMutation.isPending}>
                                {compressImageMutation.isPending ? 'Compressing...' : `Compress Selected (${selectedItems.length})`}
                            </button>
                        </div>
                    )}

                    <button className="btn btn-primary" onClick={() => setIsGridView(!isGridView)}>
                        Switch to {isGridView ? 'List' : 'Grid'} View
                    </button>
                </div>
            </div>

            <div className="mb-4 flex items-center gap-2">
                <input
                    type="text"
                    placeholder="New Folder Name"
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                    className="input w-full border input-primary p-2.5 rounded-md"
                />
                <button className="btn btn-success min-w-max" onClick={handleCreateFolder} disabled={createFolderMutation.isPending}>
                    {createFolderMutation.isPending ? 'Creating...' : 'Create Folder'}
                </button>
                <input type="file" multiple onChange={handleFileUpload} disabled={multipartUploadMutation.isPending} className="input border w-max input-primary p-2.5 rounded-md" />
                {multipartUploadMutation.isPending && <span>Uploading...</span>}
            </div>

            <FolderList folders={folders || []} onFolderClick={handleFolderClick} selectedItems={selectedItems} onSelectItem={setSelectedItems} />

            <ObjectList
                objects={objects}
                isGridView={isGridView}
                selectedItems={selectedItems}
                onSelectItem={setSelectedItems}
                onRenameClick={(key) => {
                    setObjectToRename(key);
                    setIsRenameModalOpen(true);
                    //set new file name the end name after / slash
                    setNewFileName(key?.split('/')?.pop() || '');
                }}
            />

            {hasNextObjectsPage && (
                <div className="mt-4 flex justify-center">
                    <button className="btn btn-primary" onClick={() => fetchNextObjectsPage()} disabled={isObjectsFetchingNextPage}>
                        {isObjectsFetchingNextPage ? 'Loading more objects...' : 'Load More Objects'}
                    </button>
                </div>
            )}

            {isObjectsFetching && !isObjectsFetchingNextPage ? <div className="mt-2">Fetching...</div> : null}

            <ConfirmationModal
                isOpen={isDeleteModalOpen}
                onConfirm={handleDeleteMultiple}
                onClose={() => setIsDeleteModalOpen(false)}
                message={`Are you sure you want to delete ${selectedItems.length} item(s)?`}
            />

            <ConfirmationModal
                isOpen={isCompressModalOpen}
                onConfirm={handleCompressImages}
                onClose={() => setIsCompressModalOpen(false)}
                message={`Are you sure you want to compress ${selectedItems.length} item(s)?`}
            />

            <ConfirmationModal isOpen={isRenameModalOpen} onConfirm={handleRenameSubmit} onClose={() => setIsRenameModalOpen(false)} message={`Rename ${objectToRename} to:`}>
                <input type="text" defaultValue={objectToRename} className="input w-full border input-primary p-2.5 rounded-md" value={newFileName} onChange={(e) => setNewFileName(e.target.value)} />
            </ConfirmationModal>
        </div>
    );
};

export default S3Explorer;
