import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export const BASE_URL = 'https://xyr0-v4lw-4eou.m2.xano.io/api:1sfs8wHe';

interface AxiosInstanceWithAuth extends AxiosInstance {
    interceptors: {
        request: AxiosInstance['interceptors']['request'];
        response: AxiosInstance['interceptors']['response'];
    };
}

interface InternalAxiosRequestConfig<T = any> extends AxiosRequestConfig {
    headers?: {
        Accept?: string;
        'Content-Length'?: string;
        'User-Agent'?: string;
        'Content-Encoding'?: string;
        Authorization?: string;
    };
}

const axiosInstance: AxiosInstanceWithAuth = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'ngrok-skip-browser-warning': 'true',
    },
});

axiosInstance.interceptors.request.use(
    (config: any) => {
        const token = typeof window !== 'undefined' && localStorage.getItem('token');
        if (token) {
            if (!config.headers) {
                config.headers = {};
            }
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // console.log('Unauthorized error');
        } else {
            console.error('An error occurred:', error.message);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
