import { IconCancel, IconGlobe, IconRocket, IconSeo, IconUser } from '@tabler/icons-react';
import { IconGallery, IconHome, IconMinus, IconPhoneCall } from '../components/Icon';
import { IconMenuCalendar, IconMenuContacts, IconMenuInvoice } from '../components/Icon/Menu';
import { BiGlobeAlt } from 'react-icons/bi';
import { IoPaperPlane } from 'react-icons/io5';

export const menuItems = [
    {
        title: 'Manage Shipments',

        submenu: [
            {
                title: 'Reverse Shipments',
                icon: <IconMenuContacts className="group-hover:!text-primary shrink-0" />,
                link: '/reverse-shipments',
            },
            {
                title: 'Forward Shipments',
                icon: <IconMenuContacts className="group-hover:!text-primary shrink-0" />,
                link: '/forward-shipments',
            },
            {
                title: 'All Shipments',
                icon: <IconMenuContacts className="group-hover:!text-primary shrink-0" />,
                link: '/all-shipments',
            },

            // {
            //     title: 'Manage',
            //     icon: <IconMenuInvoice className="group-hover:!text-primary shrink-0" />,
            //     submenu: [
            //         { title: 'List', link: '/apps/invoice/list' },
            //         { title: 'Preview', link: '/apps/invoice/preview' },
            //         { title: 'Add', link: '/apps/invoice/add' },
            //         { title: 'Edit', link: '/apps/invoice/edit' },
            //     ],
            // },
            {
                title: 'Pikcup Due',
                icon: <IconMenuCalendar className="group-hover:!text-primary shrink-0" />,
                submenu: [
                    { title: 'HyperLocal', link: '/pickup/HyperLocal' },
                    { title: 'Courier', link: '/pickup/Courier' },
                ],
            },
            {
                title: 'Shipment Booking',
                icon: <IconMenuCalendar className="group-hover:!text-primary shrink-0" />,
                link: '/shipment-booking',
            },
            {
                title: 'Add Address',
                icon: <IconHome className="group-hover:!text-primary shrink-0" />,
                link: '/add-address',
            },
            {
                title: 'Warehouse',
                icon: <IconMenuCalendar className="group-hover:!text-primary shrink-0" />,
                link: '/warehouse',
            },
            {
                title: 'Vendor',
                icon: <IconMenuCalendar className="group-hover:!text-primary shrink-0" />,
                link: '/vendor',
            },
            {
                title: 'Schedule',
                icon: <IconMenuCalendar className="group-hover:!text-primary shrink-0" />,
                link: '/schedule-shipment',
            },
            {
                title: 'Purchase',
                icon: <IconMenuCalendar className="group-hover:!text-primary shrink-0" />,
                link: '/purchase',
            },

            // {
            //     title: 'Shipment Booking',
            //     icon: <IconMenuCalendar className="group-hover:!text-primary shrink-0" />,
            //     link: '/shipment-booking',
            // },
        ],
    },
    {
        title: 'Management',
        submenu: [
            {
                title: 'Missed Calls',
                icon: <IconPhoneCall className="group-hover:!text-primary shrink-0" />,
                link: '/missed-calls',
            },
            {
                title: 'SEO Content',
                icon: <IconSeo className="group-hover:!text-primary shrink-0" />,
                link: '/seo-contents',
            },
            {
                title: 'Invalid Domains',
                icon: <BiGlobeAlt className="group-hover:!text-primary shrink-0" />,
                link: '/invalid-domains',
            },
            {
                title: 'Defaulters',
                icon: <IoPaperPlane className="group-hover:!text-primary shrink-0" />,
                link: '/defaulters',
            },
        ],
    },
    {
        title: 'Admin',
        submenu: [
            {
                title: 'Users',
                icon: <IconUser className="group-hover:!text-primary shrink-0" />,
                link: '/users',
            },
            {
                title: 'Roles',
                icon: <IconRocket className="group-hover:!text-primary shrink-0" />,
                link: '/roles',
            },
            {
                title: 'Resources',
                icon: <IconGlobe className="group-hover:!text-primary shrink-0" />,
                link: '/resources',
            },
        ],
    },
    {
        title: 'Apps',

        submenu: [
            // {
            //     title: 'Invoice',
            //     icon: <IconMenuInvoice className="group-hover:!text-primary shrink-0" />,
            //     submenu: [
            //         { title: 'List', link: '/apps/invoice/list' },
            //         { title: 'Preview', link: '/apps/invoice/preview' },
            //         { title: 'Add', link: '/apps/invoice/add' },
            //         { title: 'Edit', link: '/apps/invoice/edit' },
            //     ],
            // },
            // {
            //     title: 'S3 ',
            //     icon: <IconGallery className="group-hover:!text-primary shrink-0" />,
            //     link: '/s3',
            // },
            {
                title: 'S3 Explorer',
                icon: <IconGallery className="group-hover:!text-primary shrink-0" />,
                link: '/s3explorer',
            },

            {
                title: 'Calendar',
                icon: <IconMenuCalendar className="group-hover:!text-primary shrink-0" />,
                link: '/calendar',
            },
        ],
    },
];
