import React from 'react';
import { S3Folder } from '../../types/s3';

interface FolderListProps {
    folders: string[];
    onFolderClick: (prefix: string) => void;
    selectedItems: string[];
    onSelectItem: (items: string[]) => void;
}

const FolderList: React.FC<FolderListProps> = ({ folders, onFolderClick, selectedItems, onSelectItem }) => {
    const handleSelectItem = (key: string) => {
        onSelectItem(selectedItems.includes(key) ? selectedItems.filter((item) => item !== key) : [...selectedItems, key]);
    };

    return (
        <ul className="space-y-2 mb-6">
            {folders.map((folder) => (
                <li
                    key={folder}
                    className="group flex items-center cursor-pointer dark:bg-gray-800 bg-white dark:text-gray-100 dark:border-gray-700 hover:bg-gray-50 p-3 rounded-lg shadow-sm border border-gray-200 transition duration-200 ease-in-out"
                    onClick={() => onFolderClick(folder)}
                >
                    <div className="flex items-center flex-grow">
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500 transition duration-150 ease-in-out"
                            checked={selectedItems.includes(folder)}
                            onChange={() => handleSelectItem(folder)}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <div className="ml-3 flex items-center ">
                            <svg className="w-6 h-6 mr-2 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                            </svg>
                            {/* remove "/" slash from the folder */}
                            {/* <span className="font-medium">{folder}</span> */}
                            <span className="font-medium">{folder.endsWith('/') ? folder.slice(0, -1) : folder}</span>
                        </div>
                    </div>
                    <div className="text-gray-400 group-hover:text-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default FolderList;
